import 'core-js/stable';
import 'whatwg-fetch';
import './Sass/base.scss';

import React, {
  Suspense, lazy,
  useEffect,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import config from './Config/Config';
import PropsRoute from './Containers/PropsRoute';
import i18n from './Translations/i18n';
import { getAppConfig } from './reducers/appConfig';
import registerServiceWorker from './registerServiceWorker';
import store from './store';

const Logout = lazy(() => (import('./Components/Logout')));
const AppWithAuth = lazy(() => (import('./AppWithAuth')));

// Helper function to dynamically load the Intercom script
const loadIntercomScript = src => new Promise((resolve, reject) => {
  // Check if the script is already in the document
  if (document.querySelector(`script[src="${src}"]`)) {
    resolve();
    return;
  }

  const script = document.createElement('script');
  script.async = true;
  script.src = src;

  script.onload = () => resolve();
  script.onerror = e => reject(new Error(`Failed to load Intercom script: ${e.message}`));

  document.head.appendChild(script);
});

// Check if Intercom is already initialized
const isIntercomLoaded = () => typeof window.Intercom === 'function';

// Dynamically load/update Intercom based on environment
async function initializeIntercom(envName) {
  const isProd = (envName === 'prod');
  const intercomAppId = isProd ? 'tlmqy4u1' : 'slathgp8';
  const intercomSrc = `https://widget.intercom.io/widget/${intercomAppId}`;

  // 2. Load (or re-check) the Intercom script
  try {
    await loadIntercomScript(intercomSrc);
  } catch (error) {
    console.error('Error loading Intercom script:', error);
    return;
  }

  // 3. Set or merge Intercom settings
  window.intercomSettings = {
    app_id: intercomAppId,
  };

  // 4. Boot or update Intercom
  if (isIntercomLoaded()) {
    // If Intercom is already initialized, reattach and update settings
    window.Intercom('reattach_activator');
    window.Intercom('update', window.intercomSettings);
  } else {
    // If Intercom isn't initialized, queue up commands
    const i = function (...args) { i.c(args); };
    i.q = [];
    i.c = function (args) { i.q.push(args); };
    window.Intercom = i;

    // Boot Intercom with the assigned settings
    window.Intercom('boot', window.intercomSettings);
  }
}

const fetchApiData = async () => {
  try {
    await getAppConfig(); // Replace with your API URL
  } catch (error) {
    console.error('Error fetching data:', error);
    return null;
  }
};
const AppWrapper = () => {
  const [loading, setLoading] = useState(true);
  // Fetch data on component mount
  useEffect(() => {
    const loadData = async () => {
      await fetchApiData();
      setLoading(false); // Set loading to false when the API call completes
    };
    loadData();
  }, []);
  // While loading, don't render anything
  if (loading) {
    return null; // You can also return a loading spinner here if needed
  }
  return (
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <Suspense fallback={<React.Fragment />}>
            <AppWithAuth />
            <PropsRoute
              path="/logout"
              component={Logout}
            />
          </Suspense>
        </Provider>
      </I18nextProvider>
    </BrowserRouter>
  );
};
initializeIntercom(config.reactApp.ENV_NAME);
ReactDOM.render(
  <AppWrapper />,
  document.getElementById('root'),
);

registerServiceWorker();
